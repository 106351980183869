import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

export enum SnackbarEnum {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  UPDATE = 'update'
}

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressBarModule, TranslateModule]
})
export class SnackbarComponent implements OnInit {
  progress = 100;
  private currentIntervalId!: number;

  status!: number;
  alertMessage: string = '';
  lang!: string;

  duration: number = 5000;

  constructor(
    public sbRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any
  ) {
    this.sbRef.afterOpened().subscribe(
      () => {
        this.runProgressBar();
      },
      error => console.error(error)
    );
  }

  ngOnInit(): void {
    let message: string = this.data?.message || 'something_wrong';
    this.status = this.data.status;
    if (message.includes("'token'")) {
      this.alertMessage = 'dialog.unauthorized';
    } else {
      this.alertMessage = message.includes('dialog.')
        ? message
        : message === SnackbarEnum.ADD ||
            message === SnackbarEnum.EDIT ||
            message === SnackbarEnum.DELETE ||
            message === SnackbarEnum.UPDATE
          ? 'dialog.' + message
          : message;
    }
  }

  dismissWithAction() {
    this.cleanProgressBarInterval();
    this.sbRef.dismissWithAction();
  }

  /**
   * @param duration - in milliseconds
   */
  runProgressBar() {
    this.progress = 100;
    const step = 0.005;
    this.cleanProgressBarInterval();
    this.currentIntervalId = setInterval(() => {
      this.progress -= 100 * step;
      if (this.progress < 0) {
        this.cleanProgressBarInterval();
        this.sbRef.dismissWithAction();
      }
    }, this.duration * step) as unknown as number;
  }

  cleanProgressBarInterval() {
    clearInterval(this.currentIntervalId);
  }

  onHover(): void {
    clearInterval(this.currentIntervalId);
  }

  onLeave(): void {
    const step = 0.005;
    this.cleanProgressBarInterval();
    this.currentIntervalId = setInterval(() => {
      this.progress -= 100 * step;
      if (this.progress < 0) {
        this.cleanProgressBarInterval();
        this.sbRef.dismissWithAction();
      }
    }, this.duration * step) as unknown as number;
  }
}
